import { default as _91id_93qKUCL8LWIRMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/connection-drivers/[id].vue?macro=true";
import { default as indexFU7mwNCKOWMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/connection-drivers/index.vue?macro=true";
import { default as indexmAtiSnLx5fMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/index.vue?macro=true";
import { default as indexLHPB9IBanuMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/[id]/index.vue?macro=true";
import { default as merchant_45accessesZkqU1NT3HEMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/[id]/merchant-accesses.vue?macro=true";
import { default as storesqkOk4dGbxzMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/[id]/stores.vue?macro=true";
import { default as terminalsM5Y3svj5hBMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/[id]/terminals.vue?macro=true";
import { default as _91id_93fi28GzFD0eMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/[id].vue?macro=true";
import { default as indexhoslqbWZ8kMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/index.vue?macro=true";
import { default as _91id_93q9j28Px3ETMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/users/[id].vue?macro=true";
import { default as indexXOlDMrpLZPMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/users/index.vue?macro=true";
export default [
  {
    name: "connection-drivers-id",
    path: "/connection-drivers/:id()",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/connection-drivers/[id].vue")
  },
  {
    name: "connection-drivers",
    path: "/connection-drivers",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/connection-drivers/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/index.vue")
  },
  {
    name: _91id_93fi28GzFD0eMeta?.name,
    path: "/merchants/:id()",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/[id].vue"),
    children: [
  {
    name: "merchants-id",
    path: "",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/[id]/index.vue")
  },
  {
    name: "merchants-id-merchant-accesses",
    path: "merchant-accesses",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/[id]/merchant-accesses.vue")
  },
  {
    name: "merchants-id-stores",
    path: "stores",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/[id]/stores.vue")
  },
  {
    name: "merchants-id-terminals",
    path: "terminals",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/[id]/terminals.vue")
  }
]
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/merchants/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250124223721/pages/users/index.vue")
  }
]